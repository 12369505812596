/* ------------------
#header
------------------ */
.header{
    width: 100%;
    border-top-style: solid;
    border-top-width: 10px;
    border-top-color: rgb(14, 50, 207);
    background-color: #fff;
    border-radius: 5px;
  }
  .titel{
    text-align: left;
    font-size: 25px;
    padding: 15px;
  }
  .line{
    width: 100%;
    height: 1px;
    background-color: rgb(209, 209, 209);
  }
  .body{
    padding: 15px;
  
  }
  .body-mail{
    font-weight: 700;
    color: #5f6368;
  }
  .body-sub{
    color: #5f6368;
  }
  .body-req{
    color: #d93025;
  }
  