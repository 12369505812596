.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #008080;
}

.domainmail-continer{
  width: 60%;
}

.hover-download-interact:hover{
  color: green;
  cursor: pointer;
}
.hover-upload-interact:hover{
  color: turquoise;
  cursor: pointer;
}
.requestform{
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
}

.formheader{
  font-size: 18px;
  padding: 5px;
  color: rgb(20, 6, 143);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gridPadding
{
  padding:10px;
}

.sideLayout
{
  padding:0px 0px 0px 0px !important;
}

.dialogHeader {
  color: #0000cc;
  font-weight: bold;
  font-size: 120%;
}
#loginform{
  width: 30%;
  height: auto;
  margin: 50px auto;
  max-width: 500px;
  min-width: 350px;
  background-color: #FFFFFF;
  border-radius: 25px;
}
.searchfilter{
  width: 100%;
  height: auto;
  margin: 5px auto;
  background-color: #EAEDED;
  border: 0.05rem solid black;
  overflow: hidden;
}
.searchfilterheader{
  text-align: center;
  background: #f68a40;
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 1.0rem;
}
.filtersmall{ 
  width: 50%;
  height: auto;
  background-color: #EAEDED;
  border: 0.05rem solid black;
  overflow: hidden;
}
.row{
  align-items: center;
  padding-top: 1rem;
  width: 100%;  
  background-color: #FFFFFF;
}

.row input{
  width: 100%;
  margin-left: 10%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus, .registerrow input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}
.row input::-webkit-input-placeholder, .registerrow input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder,
.registerrow input:hover::-webkit-input-placeholder,
.registerrow input:focus::-webkit-input-placeholder
{
  opacity: 0;
}

.rowlabel{
  margin-left: 7%;
  color: black;
  font-size: 1.0rem;
  font-weight: 500;
}
.row button {
  border-radius: 4px;
  width: 45%;
  height: 50%;
  margin-left: 40%;
  font-size: 1.0rem;
  font-weight: 500;
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}
.loginbutton{
  margin-left: 70%;
}
.row button:hover{
  opacity: 0.8;
}
#headerTitle{
  text-align: center;
  font-family: 'open sans', sans-serif;
  margin: 0;
  font-size: 1.5rem;
}
.myfullscreen{
  position: absolute; top: 0; left: 0;  
  width: 100%;
  height: 100%;
  color: #000000;
  background: #117c70;
  overflow-y: scroll;
  overflow-x: scroll;
}
.myfooter{  
  position:absoluten;
  bottom: 0;
  width: 100%;
  font-size:small;
  text-align: center;
  color: #000000;
}
.myformitem{
  display: flex;
  flex-flow: row wrap;
}